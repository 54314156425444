var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("location-lists-editor-base", {
    attrs: {
      applicationId: _vm.applicationId,
      locationListId: _vm.locationListId,
      locationId: _vm.locationId,
      isLastLevel: true,
    },
    on: {
      onEditLocation: _vm.editLocationList,
      onBackToParent: _vm.backToParent,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }